import { Component, Vue } from 'vue-property-decorator';
import DocMixin from './doc-mixin';

const legacyVersionName = '1/2';

@Component({})
export default class VersionDocMixin extends DocMixin {
	isLegacy(item: any) {
		return !item.version || item.version === '2';
	}

	itemVersion(item: any): string {
		return this.isLegacy(item) ? legacyVersionName : item.version;
	}

	configurationDataDefault(item: any) {
		return [
			{
				component: 'view-header',
				props: {
					item: item,
					collection: 'tableToStorageConfs',
					activeHeader: true,
					viewId: this.itemId,
					viewType: 'conf',
					showDagLaunch: true,
					description: null,
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Context',
					tooltip: true,
					description: 'Context of the Table to Storage configuration',
					paramItems: [
						{ id: 'account', label: 'Account', value: item.account },
						{ id: 'environment', label: 'Environment', value: item.environment },
						{
							id: 'configuration_version',
							label: 'Configuration Version',
							value: this.itemVersion(item),
						},
					],
				},
			},
		];
	}

	runDetailsDataDefault(item: any) {
		return [
			{
				component: 'view-header',
				props: {
					item: item,
					collection: this.moduleName,
					activeHeader: true,
					viewId: item.firestore_conf_doc_id,
					viewType: 'run',
					description: null,
					runStatus: item.status,
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Context',
					tooltip: true,
					description: 'Context of the Table to Storage Run',
					paramItems: [
						{
							id: 'account',
							label: 'Account',
							value: item.account,
						},
						{
							id: 'environment',
							label: 'Environment',
							value: item.environment,
						},
						{
							id: 'client_type',
							label: 'Client Type',
							value: this.getFormattedClientType(item.client_type),
						},
						{
							id: 'client_version',
							label: 'Client Version',
							value: this.getFormattedClientVersion(item.client_version),
						},
						{
							id: 'configuration_version',
							label: 'Configuration Version',
							value: this.itemVersion(item),
						},
					],
				},
			},
		];
	}
}
